var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.inline)?_c('div',{staticClass:"common-modal-headline"},[_c('h4',{staticClass:"common-modal-title"},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t("page.company.news-create") : _vm.$t("page.company.Редагувати новину"))+" ")]),_c('span',{staticClass:"material-symbols-rounded common-modal-close",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" close ")])]):_vm._e(),(!_vm.inline)?_c('hr',{staticClass:"my-3"}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mx-auto"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":"","show":Boolean(_vm.error)}},[_vm._v(_vm._s(_vm.error))])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t("table.photo")))]),_c('div',{staticClass:"input-group input-group-merge"},[(_vm.form.picture)?_c('div',[_c('div',{staticClass:"picture-holder"},[_c('img',{attrs:{"src":_vm.form.picture.includes('data:image/')
                    ? _vm.form.picture
                    : `${_vm.form.picture}?v=${_vm.refreshPicture}`}}),_c('span',{staticClass:"material-symbols-rounded fz-24 icon-action-remove",attrs:{"title":_vm.$t('btn.remove')},on:{"click":_vm.removePicture}},[_vm._v("close")])])]):_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.form.picture),expression:"!form.picture"}],ref:"input-file-picture",staticClass:"form-control",class:{
              'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.picture.$error,
            },attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg, image/bmp"},on:{"change":_vm.uploadPicture}}),(_vm.isNew && _vm.submitted && _vm.$v.form.picture.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.password-req"))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("table.title")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
            },attrs:{"placeholder":_vm.$t('table.title')},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req"))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.news-text")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('div',{staticClass:"editor-box",class:{ 'is-invalid': _vm.$v.form.text.$error }},[_c('editor',{attrs:{"api-key":_vm.editorApiKey,"init":_vm.initShortEditor},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}})],1),(_vm.submitted && _vm.$v.form.text.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req"))+" ")]):_vm._e()])]),(!_vm.inline)?_c('hr',{staticClass:"mt-2 mb-1"}):_vm._e(),_c('div',{staticClass:"mt-3 text-center text-md-left"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t("btn.add-news") : _vm.$t("btn.save-changes"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }